import { genCard as Card0 } from "./card0.js";
import { genCard as Card1 } from "./card1.js";
import { genCard as Card2 } from "./card2.js";
import { genCard as Card3 } from "./card3.js";
import { genCard as Card4 } from "./card4.js";

export function genCard(opt) {
  switch (opt.tid) {
    case 0:
      return Card0(opt.vcard);
      break;
    case 1:
      return Card1(opt.vcard);
      break;
    case 2:
      return Card2(opt.vcard);
      break;
    case 3:
      return Card3(opt.vcard);
      break;
    case 4:
      return Card4(opt.vcard);
      break;
    default:
      throw new Error("params error");
      break;
  }
}
