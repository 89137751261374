  function genVipCard (ctx) {
    return {
      type: 'flex',
      altText: 'Utel電子名片',
      contents:  ctx,
    }
  }

  export {
      genVipCard
  }