<template>
  <div id="home">
    <van-nav-bar title="名片分享" />
    <div class="tab-section" v-cloak>
      <van-tabs :lazy-render="true" v-model:active="activeName" v-show="userInfo.nc_type > 1">
        <van-tab title="我的名片" name="0">
        </van-tab>
        <van-tab :title="state.card_title" name="1" v-if="state.showCusCard">
        </van-tab>
        <van-tab :title="card.title" :name="card.id.toString()" v-for="card of state.vip_card" :key="card.id">
        </van-tab>
      </van-tabs>
      <div class="flex-section">
        <div class="table-responsive">
          <div class="chatbox">
            <div id="flex" ref="flexRef"></div>
          </div>
        </div>
      </div>
      <div class="preview-text">上面圖內「分享」無效，請點下列「分享」</div>
    </div>
    <div class="btn-area" @click="sendEcard">
      <van-button type="primary" block>分享好友</van-button>
    </div>
    <div class="btn-area" @click="showChangeTpl" v-show="activeName == '0'">
      <van-button type="success" block>切換樣版</van-button>
    </div>
    <Footer />
    <van-popup v-model:show="showDraw" position="right" :style="{ height: '100%', width: '150px' }">
      <div>
        <ul class="tpl-list">
          <li class="tpl-item" @click="changeTpl(0)">
            <img src="@/assets/images/tpl/tpl0.png">
          </li>
          <li class="tpl-item" @click="changeTpl(1)">
            <img src="@/assets/images/tpl/tpl1.png">
          </li>
          <li class="tpl-item" @click="changeTpl(3)">
            <img src="@/assets/images/tpl/tpl3.png">
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import liff from "@line/liff";

import { Toast } from 'vant';

import { onMounted, reactive, ref, toRefs, computed, watch } from 'vue'

import { getCard, getCusCard, getVipCard, updateSendCount } from '@/api'

import { cardFactory } from '@/utils/card'

import { genCard1 } from '@/utils/card2'
import { genVipCard } from '@/utils/vipcard'
import { useStore } from 'vuex'


import Footer from '@/components/Footer'

const store = useStore()

const state = ref({
  // active: 0,
  showCusCard: false,
  card_title: computed(() => store.state.user.userInfo.card_title),
  vip_card: [],
  flexContent: {},
})

const activeName = ref('0');

let flexRef = ref(null)

const userInfo = computed(() => {
  return store.state.user.userInfo
})

onMounted(async () => {
  // console.log('liff',liff)
  await liff.init({ liffId: process.env.VUE_APP_LINE_LIFF_ID });

  if (userInfo.value.nc_type > 2) {
    let vipCardRes = await getVipCard({ userid: sessionStorage.getItem('uid') })
    state.value.vip_card = vipCardRes.data
  } else {
    state.value.vip_card = []
  }
})


watch(() => activeName.value, function (newVal, oldVal) {
  if (newVal !== oldVal) {
    showFlex(newVal)
  }
}, { immediate: true })

watch(() => userInfo.value.cus_card, function (newVal, oldVal) {
  if (userInfo.value.nc_type > 1 && newVal.length > 0) {
    state.value.showCusCard = true
  }
}, { immediate: true })

watch(() => userInfo.value.nc_template, function (newVal, oldVal) {
  console.log('new', newVal)
  showFlex('0')
})

async function showFlex(id) {

  switch (id) {
    case '0':
      let { data: res } = await getCard({ userid: sessionStorage.getItem('uid') })
      let { card } = cardFactory({ tid: userInfo.value.nc_template, vcard: res })
      // let { card } = genCard(res)
      state.value.flexContent = JSON.parse(card)
      flexRef.value.innerHTML = ''
      flex2html("flex", state.value.flexContent)
      break
    case '1':
      if (userInfo.value.nc_type > 1) {
        let card2Res = await getCusCard({ userid: sessionStorage.getItem('uid') })
        if (card2Res.code === 200) {
          if (card2Res.data.cus_card) {
            state.value.flexContent = genCard1(JSON.parse(card2Res.data.cus_card))
            flexRef.value.innerHTML = ''
            flex2html("flex", state.value.flexContent)
          }
        }
      } else {
        flexRef.value.innerHtml = ''
      }
      break
    default:
      if (userInfo.value.nc_type > 2) {
        // let vipCardRes = await getCusCard({userid: sessionStorage.getItem('uid')})
        let res = state.value.vip_card.find(item => { return item.id == id })
        if (res.type === 0) {
          state.value.flexContent = genCard1(JSON.parse(res.content))
          flexRef.value.innerHTML = ''
          flex2html("flex", state.value.flexContent)
        } else {
          state.value.flexContent = genVipCard(JSON.parse(res.content))
          flexRef.value.innerHTML = ''
          flex2html("flex", state.value.flexContent)
        }
        // if( card2Res.code === 200){
        //   if(card2Res.data.cus_card){
        //     state.flexContent = genCard1(JSON.parse(card2Res.data.cus_card))
        //     flexRef.value.innerHTML = ''
        //     flex2html("flex", state.flexContent)
        //   }
        // }
      } else {
        flexRef.value.innerHtml = ''
      }
      break
  }
}

const sendEcard = async () => {
  if (!liff.isLoggedIn()) {
    liff.login({ redirectUri: window.location.href })
  }

  let content = JSON.parse(JSON.stringify(state.value.flexContent))


  console.log(JSON.stringify(state.value.flexContent))

  let res

  if (activeName.value === '0') {
    res = await liff.shareTargetPicker([content,
      {
        'type': 'text',
        'text': '名片的連結都可以點擊!'
      }])
  } else {
    res = await liff.shareTargetPicker([content])
  }

  if (res.status === 'success') {
    let result = await updateSendCount(userInfo.value.user_id)
    Toast.success('傳送成功')
    // .then(() => {
    //   // on confirm
    // })
    // .catch(() => {
    //   // on cancel
    // })
  } else {
    Toast.fail('傳送失敗')
  }
}

const sendMsg = async () => {
  if (!liff.isLoggedIn()) {
    liff.login({ redirectUri: window.location.href })
  }
  // console.log(state.value.flexContent)
  // if(state.active===0){
  let res0 = await liff.sendMessages([JSON.parse(JSON.stringify(state.value.flexContent))])
  // }else{
  //   let res1 = await liff.sendMessages([JSON.parse(JSON.stringify(state.flexContent))])
  // }

}

const showDraw = ref(false)

function showChangeTpl() {
  showDraw.value = true
}

const changeTpl = (val) => {
  store.dispatch('user/setUserTpl', val)
  showDraw.value = false
}


</script>

<style lang="less" scoped>
.btn-area {
  padding: 5px 15px
}

.flex-section {
  // color: white;
  // z-index: 99999;
  background-color: #666;
}


.chatbox {
  background-color: #666;
  margin-top: 10px;
  padding-top: 10px;
}


.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.preview-text {
  width: 100%;
  background-color: #8e0325;
  color: #FFF;
  font-size: 18px;
  text-align: center;
}

.tpl-list {
  .tpl-item {
    padding: 10px;

    img {
      width: 100%;
    }
  }
}
</style>
