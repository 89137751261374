function genCard(vcard) {
  let vcardLink;
  if (vcard.url.trim().length > 0) {
    if (vcard.nc_func.indexOf("nourl") !== -1) {
      vcardLink = {
        type: "box",
        layout: "baseline",
        contents: [
          {
            type: "text",
            text: vcard.url,
            color: "#333334",
            size: "sm",
            weight: "bold",
            align: "end",
          },
        ],
        spacing: "lg",
        margin: "xs",
      };
    } else {
      vcardLink = {
        type: "box",
        layout: "baseline",
        contents: [
          {
            type: "text",
            text: vcard.url,
            color: "#333334",
            size: "sm",
            action: {
              type: "uri",
              label: "action",
              uri: vcard.url,
            },
            weight: "bold",
            align: "end",
          },
        ],
        spacing: "lg",
        margin: "xs",
      };
    }
  } else {
    vcardLink = {
      type: "box",
      layout: "baseline",
      contents: [
        {
          type: "text",
          text: " ",
          color: "#333334",
          size: "sm",
          weight: "bold",
          align: "end",
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  }

  let vcardAddr;
  if (vcard.address.trim().length > 0) {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: vcard.address,
          weight: "bold",
          color: "#333334",
          size: "sm",
          align: "end",
          action: {
            type: "uri",
            label: "action",
            uri: `https://www.google.com.tw/maps/place/${encodeURIComponent(
              vcard.address
            )}`,
          },
          wrap: true,
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  } else {
    vcardAddr = {
      type: "box",
      layout: "vertical",
      contents: [
        {
          type: "text",
          text: " ",
          weight: "bold",
          color: "#333334",
          size: "sm",
          align: "end",
          wrap: true,
        },
      ],
      spacing: "lg",
      margin: "xs",
    };
  }

  if (vcard.title.trim().length == 0) {
    vcard.title = " ";
  }

  if (vcard.tel.trim().length == 0) {
    vcard.tel = " ";
  }

  let vcardPhone;

  if (vcard.phone.trim().length == 0) {
    vcard.phone = " ";
  }

  let vcardAvatar;
  if (vcard.avatar.trim().length > 0) {
    vcardAvatar = {
      type: "image",
      url: vcard.avatar,
      action: {
        type: "uri",
        label: "action",
        uri: vcard.nfcurl + "&cardid=1",
      },
    };
  } else {
    vcardAvatar = {
      type: "text",
      text: " ",
      size: "xs",
    };
  }

  let vcardEmail;

  if (vcard.email.trim().length > 0) {
    vcardEmail = {
      type: "text",
      text: vcard.email,
      color: "#333334",
      size: "sm",
      weight: "bold",
      action: {
        type: "uri",
        label: "action",
        uri: `mailto:${vcard.email}`,
      },
      align: "end",
    };
  } else {
    vcardEmail = {
      type: "text",
      text: " ",
      color: "#333334",
      size: "sm",
      weight: "bold",
      align: "end",
    };
  }

  let card = {
    altText: "UTel電子名片",
    type: "flex",
    contents: {
      type: "bubble",
      size: "giga",
      body: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "image",
            size: "full",
            aspectMode: "cover",
            aspectRatio: "4:3",
            gravity: "top",
            url: process.env.VUE_APP_BASE_URL+"/images/tpl03_bg.png?v=1",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: vcard.company || " ",
                size: "xl",
                wrap: true,
              },
            ],
            position: "absolute",
            offsetTop: "5%",
            offsetStart: "5%",
            width: "60%",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [vcardAvatar],
            position: "absolute",
            offsetTop: "5%",
            offsetEnd: "5%",
          },
          {
            type: "box",
            layout: "horizontal",
            contents: [
              {
                type: "text",
                text: vcard.name,
                size: "lg",
                weight: "bold",
                align: "center",
              },
              // {
              //   type: "text",
              //   text: " ",
              //   size: "lg",
              //   weight: "bold",
              //   color: "#F71646",
              // },
            ],
            position: "absolute",
            offsetStart: "40%",
            offsetTop: "45%",
            width: "20%",
          },
          {
            type: "box",
            layout: "vertical",
            contents: [
              {
                type: "text",
                text: vcard.title || " ",
                align: "center",
              },
            ],
            position: "absolute",
            offsetTop: "55%",
            offsetStart: "40%",
            width: "20%",
          },
          {
            type: "box",
            layout: "horizontal",
            contents: [
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://utel.u168.vip/images/icons/web.png",
                    align: "center",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: "我的網站",
                    align: "center",
                    color: "#FFFFFF",
                  },
                ],
                flex: 1,
                action: {
                  type: "uri",
                  label: "action",
                  uri:
                    vcard.url.trim().length > 0
                      ? vcard.url
                      : vcard.nfcurl + "&cardid=1",
                },
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://utel.u168.vip/images/icons/phone.png",
                    align: "center",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: vcard.phone,
                    align: "center",
                    color: "#FFFFFF",
                  },
                ],
                flex: 1,
                action: {
                  type: "uri",
                  label: "action",
                  uri: "tel:" + vcard.phone,
                },
              },
              {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: "https://utel.u168.vip/images/icons/email.png",
                    align: "center",
                    size: "xxs",
                  },
                  {
                    type: "text",
                    text: vcard.email,
                    align: "center",
                    color: "#FFFFFF",
                  },
                ],
                flex: 1,
                action: {
                  type: "uri",
                  label: "action",
                  uri: "mailto:" + vcard.email,
                },
              },
            ],
            position: "absolute",
            width: "100%",
            offsetBottom: "5%",
          },
        ],
        paddingAll: "0px",
        action: {
          type: "uri",
          label: "action",
          uri: vcard.nfcurl + "&cardid=1",
        },
      },
    },
  };
  console.log(JSON.stringify(card));
  return { card: JSON.stringify(card) };
}

export { genCard };
